import { Navigation } from "@shopify/polaris";
import { useLocation } from "react-router-dom";
import {
  AnalyticsMajor,
  MarketingMajor,
  FormsMajor,
  OrderStatusMinor,
  LiveViewMajor,
  ExchangeMajor,
  ChecklistMajor,
  CustomersMinor,
  SettingsMinor,
} from "@shopify/polaris-icons";

import { useGetMeQuery } from "../redux/services/users";
import { uniqBy } from "lodash";

const Sidebar = () => {
  const rLocation = useLocation();
  //const result =  useGetUsersQuery(undefined, { pollingInterval: 2000 });
  const { data } = useGetMeQuery();

  // const admin = [
  //   {
  //     url: "/dashboard",
  //     label: "Dashboard",
  //     icon: LiveViewMajor,
  //   },
  //   {
  //     url: "/alterations",
  //     label: "Alterations",
  //     exactMatch: true,
  //     icon: ExchangeMajor,
  //   },
  //   {
  //     url: "/orders",
  //     label: "Orders",
  //     exactMatch: true,
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/statuses/alterations",
  //     label: "Alteration Statuses",
  //     exactMatch: true,
  //     icon: AnalyticsMajor,
  //     subNavigationItems: [
  //       {
  //         url: "/statuses/alterations",
  //         disabled: false,
  //         label: "View all",
  //       },
  //       {
  //         url: "/sequence/alterations",
  //         disabled: false,
  //         label: "Sequence",
  //       },
  //     ],
  //   },
  //   {
  //     url: "/statuses/orders",
  //     label: "Order Statuses",
  //     icon: OrderStatusMinor,
  //     exactMatch: true,
  //     subNavigationItems: [
  //       {
  //         url: "/statuses/orders",
  //         disabled: false,
  //         label: "View all",
  //       },
  //       {
  //         url: "/sequence/orders",
  //         disabled: false,
  //         label: "Sequence",
  //       },
  //     ],
  //   },
  //   {
  //     url: "/form-responses",
  //     label: "Form Responses",
  //     icon: FormsMajor,
  //   },
  //   {
  //     url: "/custom-date-range",
  //     label: "Analytics",
  //     icon: OrderStatusMinor,
  //     subNavigationItems: [
  //       {
  //         url: "/custom-date-range",
  //         disabled: false,
  //         label: "Custom Date Range",
  //       },
  //       {
  //         url: "/single-day",
  //         disabled: false,
  //         label: "Single Day",
  //       },
  //       {
  //         url: "/monthly-breakdown",
  //         disabled: false,
  //         label: "Monthly Breakdown",
  //       },
  //     ],
  //   },
  //   {
  //     url: "/customers",
  //     label: "Customers",
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/qc",
  //     label: "Quality Control",
  //     icon: ChecklistMajor,
  //   },
  //   {
  //     url: "/users",
  //     label: "Users",
  //     icon: ChecklistMajor,
  //   },
  // ];
  // const alterations = [
  //   {
  //     url: "/alterations",
  //     label: "Alterations",
  //     exactMatch: true,
  //     icon: ExchangeMajor,
  //   },

  //   {
  //     url: "/customers",
  //     label: "Customers",
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/qc",
  //     label: "Quality Control",
  //     icon: ChecklistMajor,
  //   },
  // ];
  // const optertions = [
  //   {
  //     url: "/alterations",
  //     label: "Alterations",
  //     exactMatch: true,
  //     icon: ExchangeMajor,
  //   },
  //   {
  //     url: "/orders",
  //     label: "Orders",
  //     exactMatch: true,
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/customers",
  //     label: "Customers",
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/qc",
  //     label: "Quality Control",
  //     icon: ChecklistMajor,
  //   },
  // ];

  // const customerService = [
  //   {
  //     url: "/alterations",
  //     label: "Alterations",
  //     exactMatch: true,
  //     icon: ExchangeMajor,
  //   },
  //   {
  //     url: "/orders",
  //     label: "Orders",
  //     exactMatch: true,
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/customers",
  //     label: "Customers",
  //     icon: MarketingMajor,
  //   },
  //   {
  //     url: "/qc",
  //     label: "Quality Control",
  //     icon: ChecklistMajor,
  //   },
  // ];
  const routes = {
    3: [
      {
        url: "/dashboard",
        label: "Dashboard",
        icon: LiveViewMajor,
      },
      {
        url: "/alterations",
        label: "Alterations",
        exactMatch: true,
        icon: ExchangeMajor,
      },
      {
        url: "/orders",
        label: "Orders",
        exactMatch: true,
        icon: MarketingMajor,
      },
      {
        url: "/statuses/alterations",
        label: "Alteration Statuses",
        exactMatch: true,
        icon: AnalyticsMajor,
        subNavigationItems: [
          {
            url: "/statuses/alterations",
            disabled: false,
            label: "View all",
          },
          {
            url: "/sequence/alterations",
            disabled: false,
            label: "Sequence",
          },
        ],
      },
      {
        url: "/statuses/orders",
        label: "Order Statuses",
        icon: OrderStatusMinor,
        exactMatch: true,
        subNavigationItems: [
          {
            url: "/statuses/orders",
            disabled: false,
            label: "View all",
          },
          {
            url: "/sequence/orders",
            disabled: false,
            label: "Sequence",
          },
        ],
      },
      {
        url: "/form-responses",
        label: "Form Responses",
        icon: FormsMajor,
      },
      {
        url: "/custom-date-range",
        label: "Analytics",
        icon: OrderStatusMinor,
        subNavigationItems: [
          {
            url: "/custom-date-range",
            disabled: false,
            label: "Custom Date Range",
          },
          {
            url: "/single-day",
            disabled: false,
            label: "Single Day",
          },
          {
            url: "/monthly-breakdown",
            disabled: false,
            label: "Monthly Breakdown",
          },
        ],
      },
      {
        url: "/customers",
        label: "Customers",
        icon: MarketingMajor,
      },
      {
        url: "/qc",
        label: "Quality Control",
        icon: ChecklistMajor,
      },
      {
        url: "/users",
        label: "Users",
        icon: CustomersMinor,
      },
      {
        url: "/settings",
        label: "Settings",
        icon: SettingsMinor,
      },
    ],
    6: [
      {
        url: "/settings",
        label: "Settings",
        icon: SettingsMinor,
      },
    ],
    5: [
      {
        url: "/dashboard",
        label: "Dashboard",
        icon: LiveViewMajor,
      },
      {
        url: "/alterations",
        label: "Alterations",
        exactMatch: true,
        icon: ExchangeMajor,
      },
      {
        url: "/customers",
        label: "Customers",
        icon: MarketingMajor,
      },
      {
        url: "/qc",
        label: "Quality Control",
        icon: ChecklistMajor,
      },
    ],
    4: [
      {
        url: "/dashboard",
        label: "Dashboard",
        icon: LiveViewMajor,
      },
      {
        url: "/alterations",
        label: "Alterations",
        exactMatch: true,
        icon: ExchangeMajor,
      },
      {
        url: "/orders",
        label: "Orders",
        exactMatch: true,
        icon: MarketingMajor,
      },
      {
        url: "/customers",
        label: "Customers",
        icon: MarketingMajor,
      },
      {
        url: "/qc",
        label: "Quality Control",
        icon: ChecklistMajor,
      },
    ],
    1: [
      {
        url: "/alterations",
        label: "Alterations",
        exactMatch: true,
        icon: ExchangeMajor,
      },
      {
        url: "/orders",
        label: "Orders",
        exactMatch: true,
        icon: MarketingMajor,
      },
      {
        url: "/customers",
        label: "Customers",
        icon: MarketingMajor,
      },
      {
        url: "/qc",
        label: "Quality Control",
        icon: ChecklistMajor,
      },
    ],
  };

  const getRoutes = () => {
    if (data?.is_superuser) {
      return routes[3];
    } else {
      const newR = Object.keys(routes)
        .filter((roleKey) => data?.groups.includes(Number(roleKey)))
        .map((roleKey) => routes[roleKey])
        .flat();

      return uniqBy(newR, "url");
    }
  };
  return (
    <Navigation location={rLocation.pathname}>
      <Navigation.Section items={getRoutes() || []} />
    </Navigation>
  );
};

export default Sidebar;
